import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SecondPageHeader from "../../container/secondPageHaaderContainer"
import CallToAction from "../../container/ctaContainer"
import { Link } from "gatsby"
import ProductDocumentPdf from "../../images/pdf/wakachie_202211.pdf"
import {
  trackingEvent,
  CLICK_ACTION,
  DOWNLOAD_PAGE_CATEGORY,
  WHIEPAPER_DOWNLOAD_LABEL,
  PRODUCTDOCUMENT_DOWNLOAD_LABEL
} from "../../tools/Tracking"

const DownloadPage = () => {
  return (
    <Layout>
      <Seo title="資料ダウンロードページ | 顔を出さないで感情の「見える化」をカンタンに。オンライン会議サポートツール | ワカチエ" />
      <SecondPageHeader englishTitle="- document download -" mainTitle="資料ダウンロードページ" isShowTrialLink={true} />
      <div className="downloadContainer secondPageContens">
        <p>
          資料請求いただきありがとうございます。
          <br /> こちらのページより資料のダウンロードが可能です。
        </p>

        <a href={ProductDocumentPdf} download className="linkButton"  onClick={() =>
          trackingEvent(CLICK_ACTION, DOWNLOAD_PAGE_CATEGORY, PRODUCTDOCUMENT_DOWNLOAD_LABEL)
        }>
          製品資料 ダウンロード
        </a>
        <br /><br /><br />
        <p>ワカチエの特徴をご紹介するホワイトペーパーを公開中！<br />是非ご一読ください。</p>
        
        <Link to="/download/psychologicalsafety" className="linkButton" onClick={() =>
          trackingEvent(CLICK_ACTION, DOWNLOAD_PAGE_CATEGORY, WHIEPAPER_DOWNLOAD_LABEL)
        }>
          心理的安全性について[ホワイトペーパー]
        </Link>
        <br /><br />
        <Link to="/download/kawaii-character" className="linkButton" onClick={() =>
          trackingEvent(CLICK_ACTION, DOWNLOAD_PAGE_CATEGORY, WHIEPAPER_DOWNLOAD_LABEL)
        }>
          「かわいさ」が会議を変える！仕事を変える！[ホワイトペーパー]
        </Link>
      </div>
      <CallToAction />
    </Layout>
  )
}

export default DownloadPage
